import React from 'react';
import imagenmain1 from './dma.png'; // Asegúrate de actualizar esta ruta a la ubicación real de tu imagen.
import { Helmet } from 'react-helmet';

const paragraphStyle = {
  marginBottom: '50px',
  };
  const titleStyle = {
    marginTop: '0',
    marginBottom: '50px',
  };
const Home3 = () => {
  return (
    <div style={styles.mainContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Discover SPOOTING's private, undetectable cheating and dma firmware solutions with 24/7 support and no downtime due to game updates." />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/" />
      </Helmet>
      <div style={styles.mainTexto}>
        <main style={styles.MainImage}>
          {/* Aquí añadimos la imagen */}
          <div>
             <img src={imagenmain1} alt="Logo" style={styles.imagen} />
          </div>
        </main>
        <main style={styles.MainText}> 
     
        <section>
        <h2 style={titleStyle}> DMA Hardware Cheats</h2>
        <p style={paragraphStyle}>Direct Memory Access - the safest type of hardware cheats. It bypass any anti-cheats, because a cheat runs on second PC and cannot be detected.</p>
        </section>  

        <section>
        <p style={paragraphStyle}>Imagine our machine learning aimbots as your inner pro gamer watching your game screen, helping you move your crosshair onto targets whenever you need the assistance. All this magic happens while you play.</p>
        </section>

        <section>
        <p style={paragraphStyle}>Unlike other providers SPOOTINGCHEATS cheats never mess with game files, the games memory or anything else which could cause a anti cheat detection. This makes this technology unique and the future of hacking in video games.</p>
        </section>
       {/* Nueva sección de ítems con verificación */}
       <div style={styles.verificationList}>
          <div style={styles.verificationItem}>✓ FACEIT</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp; ✓ ESEA</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp; ✓ ESPORTAL</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp; ✓ AKROS</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp; ✓ GAMERSCLUB</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  ✓ VANGUARD</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp; ✓ VANGUARD ACE</div> 
          <div style={styles.verificationItem}>&nbsp; &nbsp; ✓ EASYANTICHEAT</div> 
        </div>
      </main>
        </div>
    </div>
  );
};
const styles = {
  mainContainer: {
    color: '#fff',
    backgroundColor: '#000',
    padding: '20px',
  },    
  mainTexto: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    alignContent: 'space-between' ,
    paddingTop: '3%' ,
    paddingBottom: '3%' ,
    paddingLeft: '15%',
    paddingRight: '15%',
  } ,
      imagen: {

      height: '500px', // La altura se ajustará automáticamente para mantener la proporción
      borderRadius: '5%' ,
      width: '115%',

    },
    MainLeft: {
      width: '35%' ,
      marginLeft: '10%' ,
    } ,
    MainRight: {
      width: 'auto' ,
      height: 'auto' ,
      marginRight: '20%' ,
      marginTop: '1.1%' ,
    },
    MainImage: {
      width: '500px',
      marginRight: '5%', // Aumenta este valor para añadir más espacio entre la imagen y el texto
      // ... otros estilos que necesites ...
    },
    MainText: {
      flex: 1,
      marginLeft: '5%', // Ajusta el margen izquierdo si es necesario
      // ... otros estilos que necesites ...
    },
    verificationList: {
        display: 'flex',
        justifyContent: 'start', // Alinea los elementos al inicio del contenedor
        alignItems: 'center',
        flexWrap: 'wrap', // Permite que los elementos pasen a la siguiente línea si no hay espacio
        // ... el resto de los estilos que necesites ...
        marginTop: '5%'
    },
    verificationItem: {
        color: '#1AFC03', // Asegúrate de que el color sea visible contra tu fondo
        marginRight: '20px', // Espacio a la derecha de cada ítem
        // ... el resto de los estilos que necesites ...
        marginTop: '4%',
        marginLeft:'1%'
    },
};

export default Home3;