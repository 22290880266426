import React from 'react';
import mes from './3meses.png';
import meses from './1mes.png';
import lifetime from './lifetime.png';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { Helmet } from 'react-helmet';

const packages = [
  {
    name: "CS2 DMA 1 MONTH",
    price: "$40",
    features: [
      "✅ CS2 DMA CHEAT FOR 1 MONTH",
      "✅ Support 24/7 via Discord",
      "✅ Anticheats supported: ",
      "\u00A0\u00A0\u00A0\u00A0\u00A0💎 FACE IT",
      "\u00A0\u00A0\u00A0\u00A0\u00A0💎 ESEA",
      "\u00A0\u00A0\u00A0\u00A0\u00A0💎 ESPORTAL",
      "\u00A0\u00A0\u00A0\u00A0\u00A0💎 AKROS",
      "\u00A0\u00A0\u00A0\u00A0\u00A0💎 GAMERSCLUB",
    ],
    image: mes, 
    path: '/buy-cs2dma-1month',
  },
  {
    name: "CS2 DMA 3 MONTH",
    price: "$100",
    features: [
        "✅ CS2 DMA CHEAT FOR 3 MONTH",
        "✅ Support 24/7 via Discord",
        "✅ Anticheats supported: ",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 FACE IT",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 ESEA",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 ESPORTAL",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 AKROS",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 GAMERSCLUB",
    ],
    image: meses, // Replace with dmaCardImage if using local imports
    path: '/buy-cs2dma-3months',
  },
  {
    name: "CS2 DMA LIFETIME",
    price: "$300",
    features: [
        "✅ CS2 DMA CHEAT FOR LIFETIME",
        "✅ Support 24/7 via Discord",
        "✅ Anticheats supported: ",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 FACE IT",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 ESEA",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 ESPORTAL",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 AKROS",
        "\u00A0\u00A0\u00A0\u00A0\u00A0💎 GAMERSCLUB",
    ],
    image: lifetime, // Replace with fullPackageImage if using local imports
    path: '/buy-cs2dma-lifetime',
  },
];

const PurchasePage = () => {
  let navigate = useNavigate(); // Hook para la navegación

  // Función para manejar la redirección al hacer clic en el botón
  const handleBuyNowClick = (path) => {
    // Aquí puedes implementar lógica adicional si necesitas pasar datos a la página de compra
    navigate(path);
  };

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="CS2 DMA private, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, DMA, DMA cheats, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/cs2-dma" />
      </Helmet>
      {packages.map((pkg, index) => (
        <div key={index} style={styles.card}>
          <h3>{pkg.name}</h3>
          <img src={pkg.image} alt={pkg.name} style={styles.image} />
          <div style={styles.price}>{pkg.price}</div>
          <ul style={styles.featureList}>
            {pkg.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <button style={styles.buyButton} onClick={() => handleBuyNowClick(pkg.path)}>
            Buy now
          </button>
        </div>
      ))}
    </div>
  );
};
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#060606',
  },
  card: {
    width: '300px',
    padding: '20px',
    margin: '10px',
    backgroundColor: '#111',
    color: 'white',
    borderRadius: '10px',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '20px',
  },
  price: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  featureList: {
    textAlign: 'left',
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '16px',
  },
  buyButton: {
    backgroundColor: '#61dafb',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '18px',
    cursor: 'pointer',
    marginTop: '20px',
  }
};

export default PurchasePage;
