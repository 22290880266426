import React, { useState, useEffect } from 'react';
import Discord from './discord.png';
import { Helmet } from 'react-helmet';

const Create = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [spinnerAngle, setSpinnerAngle] = useState(0);
  const [requestCount, setRequestCount] = useState(34893);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setSpinnerAngle((prevAngle) => (prevAngle + 360 / 60) % 360);
      }, 1000 / 60);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    setRequestCount(prevCount => prevCount + 1); // Incrementa el contador cada vez que se pulsa el botón

    // Simula la llamada a la API
    setTimeout(() => {
      if (code !== 'elCódigoCorrecto') {
        setError('Incorrect Code');
      } else {
        // Manejo del caso de éxito
      }
      setLoading(false);
    }, 2000);
  };
  
  return (
    <div style={styles.outerContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Create account, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, Valorant, Rust, DMA, Firmware, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/register" />
      </Helmet>
      <div style={styles.leftContainer}>
      <a href="https://discord.com/invite/TDkETDyMB5">
             <img src={Discord} alt="Descripción" style={styles.image} />
             </a>
            <p style={styles.text}>To join us please join our Application Discord</p>
            <p style={styles.text}>The only safe way to apply is via our Application Discord.</p>
            <p style={styles.text}>WE WILL NOT MESSAGE YOU FIRST INSIDE THE DISCORD.</p>
            <p style={styles.text}>Please make sure you message the right person..</p>
      </div>
      {/* Cierre del primer div antes de iniciar el siguiente */}
      
      {/* Contenedor para el formulario de código de invitación */}
      <div style={styles.loginContainer}>
        <h2 style={styles.loginTitle}>INVITATION CODE</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <label htmlFor="code" style={styles.label}>Put the Code here:</label>
          <input
            id="code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            style={styles.input}
          />
          <button type="submit" style={styles.submitButton} disabled={loading}>
            {loading ? 'Checking...' : 'Submit'}
          </button>
          {error && <div style={styles.errorMessage}>{error}</div>}
        </form>
        {loading && (
          <>
            <div style={styles.spinnerOverlay}>
              <div style={{ ...styles.spinner, transform: `rotate(${spinnerAngle}deg)` }} />
            </div>
            <div style={styles.loadingIndicator}>
              SPOOTINGCHEATS.com/db-link/req-{requestCount}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
const styles = {
loginContainer: {
    color: 'white',
    backgroundColor: '#181a1b',
    padding: '40px',
    borderRadius: '5px',
    maxWidth: '300px', // O el ancho que prefieras
    margin: 'auto',
    marginBottom: '5.5%' ,
    marginTop: '8%' ,
  },
  loginTitle: {
    textAlign: 'center',
    marginBottom: '15%',
    backgroundColor: '#181a1b',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#181a1b',
  },
  label: {
    marginBottom: '5px',
  },
  input: {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc', // Personaliza el borde como prefieras
    marginTop: '5px'
  },
  lostPassword: {
    display: 'block',
    marginBottom: '15px',
    color: 'lightblue', // Personaliza el color como prefieras
    textDecoration: 'none',
  },
  submitButton: {
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#3498db', // Personaliza el color del botón como prefieras
    color: 'white',
    cursor: 'pointer',
    marginTop: '5%'
  },
  errorMessage: {
    color: 'red', // El mensaje de error en rojo
    // Otros estilos para el mensaje de error si es necesario
    marginTop: '10%'
  },
  spinnerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1000,
    paddingBottom: '5%'
  },
  spinner: {
    border: '20px solid #f3f3f3', // Aumentado de 5px a 8px para un aspecto más "gordo"
    borderTop: '20px solid #3498db', // Aumentado de 5px a 8px para mantener la consistencia
    borderRadius: '50%',
    width: '60px', // Aumentado de 50px a 60px para un spinner más grande
    height: '60px', // Aumentado de 50px a 60px para un spinner más grande
  },
  loadingIndicator: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    backgroundColor: 'transparent', // Fondo transparente
    color: '#CCC', // Un color gris claro, similar al de los navegadores
    fontSize: '12px', // Un tamaño de fuente pequeño para simular el indicador del navegador
    padding: '5px 10px', // Un poco de relleno alrededor del texto
    fontFamily: 'sans-serif', // Fuente por defecto del navegador
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'space-around', // Centra los hijos horizontalmente con espacio alrededor
    alignItems: 'center', // Centra los hijos verticalmente
    height: '100vh', // Ocupa toda la altura de la pantalla
    padding: '40px', // Espacio alrededor del contenido interno
    backgroundColor: '#000', // Fondo negro para todo el contenedor exterior
  },
  leftContainer: {
    flex: 1, // Ocupa la mitad del espacio disponible
    display: 'flex',
    flexDirection: 'column', // Organiza los hijos en columna (uno debajo del otro)
    alignItems: 'center', // Centra horizontalmente los hijos en el contenedor
    marginRight: '20px', // Espacio entre el contenedor izquierdo y el formulario
  },
  loginContainer: {
    flex: 1, // Ocupa la mitad del espacio disponible
    display: 'flex',
    flexDirection: 'column', // Organiza los hijos en columna (uno debajo del otro)
    alignItems: 'center', // Centra horizontalmente los hijos en el contenedor
    padding: '40px',
    borderRadius: '5px',
    backgroundColor: '#181a1b',
  },
  // ... (Los demás estilos permanecen igual)
  image: {
    width: '200px', // Ajusta según el tamaño deseado
    height: 'auto', // Mantiene la proporción de la imagen
    marginBottom: '10px', // Espacio entre la imagen y el texto
  },
  text: {
    color: 'white', // Texto blanco
    // Añade más estilos para el texto si es necesario
  },
};

export default Create;