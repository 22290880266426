import React from 'react';
import StatusTableComponent from './tabla';
import { Helmet } from 'react-helmet';

const Status = () => {
  return (
    <div style={styles.mainContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Cheat Status SPOOTING's private, undetectable cheating and dma firmware solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="Cheat status, Status, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/Status" />
      </Helmet>
      <div style={styles.header}>
        <h1>Hacks Status</h1>
      </div>
      {/* Barra de estado con estilos en línea */}
      <div>
        <span style={{ ...styles.statusItem, backgroundColor: '#28a745' }}>Undetected</span>
        <span style={{ ...styles.statusItem, backgroundColor: '#dc3545' }}>Detected</span>
        <span style={{ ...styles.statusItem, backgroundColor: '#6c757d' }}>Offline</span>
        <span style={{ ...styles.statusItem, backgroundColor: '#ffc107', color: 'black' }}>At your own risk</span>
        <span style={{ ...styles.statusItem, backgroundColor: '#17a2b8' }}>Updating</span>
      </div>
      <div style={styles.tableContainer}>
        <table style={styles.statusTable}>
          {/* ... */}
        </table>
        <div style={
          {
          marginLeft: '20%',
          marginRight: '20%' ,
          marginTop: '2%',
          }
          }>
          <StatusTableComponent/>
        </div>
      </div>
      <div style={styles.supportedSystems}>
        <h1>Supported systems: Windows 10 / Windows 11</h1>
      </div>
    </div>
  );
};

// Aquí se pueden agregar estilos adicionales
const styles = {
  mainContainer: {
    color: '#fff',
    backgroundColor: '#000',
    minHeight: '100vh',
    padding: '20px',
    fontFamily: 'sans-serif', // Asegúrate de usar la familia de fuentes deseada
  },
  header: {
    textAlign: 'center',
    marginBottom: '1em',
  },
  statusItem: {
    padding: '5px 15px',
    borderRadius: '4px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    marginRight: '5px', // Añade un poco de margen entre los elementos
    // No olvides agregar las clases correspondientes en tu archivo CSS o aquí mismo
  },
  tableContainer: {
    marginTop: '20px',
    overflowX: 'auto',
  },
  statusTable: {
    width: '100%',
    borderCollapse: 'collapse',
    // Puedes necesitar ajustar el estilo de la tabla aquí
  },
  statusIndicator: {
    textAlign: 'center',
    fontWeight: 'bold',
    // Añade estilos adicionales según sea necesario
  },
  supportedSystems: {
    textAlign: 'center',
    marginTop: '200px',

  },
  // Estilos adicionales según sea necesario
};

export default Status;
