import React from 'react';
import cheatImage from './eacbe.png';
import vgace from './vgace.png';
import faceit from './faceit.png';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { Helmet } from 'react-helmet';

const packages = [
  {
    name: "EAC/BE FIRMWARE",
    price: "$150",
    features: [
      "✅ 1:1 Custom Private Firmware",
	  "✅ Emulation Real Device",
      "✅ Manufacturer driver support",
	  "✅ Bypass any manual scan",
      "✅ Supports All Winver",
      "✅ Compatible with All Mobo Brands",
    ],
    image: cheatImage, 
    path: '/buy-eacbe-firmware',
  },
  {
    name: "VALORANT FIRMWARE",
    price: "$300",
    features: [
      "✅ 1:1 Custom Private Firmware",
	  "✅ Emulation Real Device",
	  "✅ Manufacturer driver support",
	  "✅ Bypass any manual scan",
      "✅ Supports All Winver",
      "✅ Compatible with All Mobo Brands",
      "✅ Supports Either Vanguard or ACE (U have to choose one) [ACE is for china users]",
      "💎 This firmware needs customization based on the user's environment, so users are required to provide devices list after purchase. Detailed instructions will be provided after payment",
    ],
    image: vgace, // Replace with dmaCardImage if using local imports
    path: '/buy-valorant-firmware',
  },
  {
    name: "FACE IT FIRMWARE",
    price: "$500",
    features: [
      "✅ 1:1 Custom Private Firmware",
      "✅ Emulation Real Device",
      "✅ Manufacturer driver support",
      "✅ Bypass any manual scan",
      "✅ Supports All Winver",
      "✅ Compatible with All Mobo Brands",
      "💎 This firmware emulate real device, when you buy it you can choose what kind of firmware you prefer, at the moment we have several fully emulated devices (network card, capture card, multimedia card, serial port) [We will not publish the actual names of the emulated devices for security reasons].",
    ],
    image: faceit, // Replace with fullPackageImage if using local imports
    path: '/buy-faceit-firmware',
  },
];

const PurchasePage = () => {
  let navigate = useNavigate(); // Hook para la navegación

  // Función para manejar la redirección al hacer clic en el botón
  const handleBuyNowClick = (path) => {
    // Aquí puedes implementar lógica adicional si necesitas pasar datos a la página de compra
    navigate(path);
  };

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Private DMA Firmware for EAC/BE, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="EAC, BE, FACEIT, Face it, Valorant, Vanguard, ACE, Firmware, Undetected, warranty, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/Firmware" />
      </Helmet>
      {packages.map((pkg, index) => (
        <div key={index} style={styles.card}>
          <h3>{pkg.name}</h3>
          <img src={pkg.image} alt={pkg.name} style={styles.image} />
          <div style={styles.price}>{pkg.price}</div>
          <ul style={styles.featureList}>
            {pkg.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <button style={styles.buyButton} onClick={() => handleBuyNowClick(pkg.path)}>
            Buy now
          </button>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#060606',
  },
  card: {
    width: '300px',
    padding: '20px',
    margin: '10px',
    backgroundColor: '#111',
    color: 'white',
    borderRadius: '10px',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '20px',
  },
  price: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  featureList: {
    textAlign: 'left',
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '16px',
  },
  buyButton: {
    backgroundColor: '#61dafb',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '18px',
    cursor: 'pointer',
    marginTop: '20px',
  }
};

export default PurchasePage;
