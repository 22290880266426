import React from 'react';
import { Link } from 'react-router-dom';
import cs2 from './cs2.jpg';
import valorant from './valorant.jpg';
import rust from './rust.jpg';
import { Helmet } from 'react-helmet';

// Componente de categoría individual
const Category = ({ title, image, path }) => {
    return (
      <div style={{...styles.categoryContainer, backgroundImage: `url(${image})`}}>
        <Link to={path} style={styles.categoryContent}>
          <h2 style={styles.categoryContentH2}>{title}</h2>
        </Link>
      </div>
    );
  };
// Componente de la página de la tienda que usa Category
const StorePage = () => {
  return (
   
    <div style={styles.storePage}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Private DMA Firmware for EAC/BE, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="Valorant, CS, Counter Strike 2, Rust, DMA cheats, DMA, Face it, Valorant, Vanguard, ACE, Firmware, Undetected, warranty, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/Hacks" />
      </Helmet>
      <h1>Store</h1>
      <div style={styles.categories}>
      <div style={styles.categoryContainer}><Category title="Counter Strike 2" image={cs2} path="/cs2" /></div>
      <div style={{...styles.categoryContainer}}><Category title="Counter Strike 2 - DMA" image={cs2} path="/cs2-dma" /></div>
      <div style={styles.categoryContainer}><Category title="Valorant" image={valorant} path="/valorant" /></div>
      <div style={{...styles.categoryContainer}}><Category title="Valorant - DMA" image={valorant} path="/valorant-dma" /></div>
      <div style={{...styles.categoryContainer2}}><Category title="Rust" image={rust} path="/rust" /></div>
    </div>
   </div>
  );
};
const styles = {
    storePage: {
        color: '#fff',
        backgroundColor: '#000',
        padding: '100px',
        boxSizing: 'border-box', // Asegura que el padding no aumente el tamaño del elemento
    },
    categories: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around', // Asegúrate de que los elementos se distribuyan de manera equitativa
        gap: '10px', // Espacio entre los elementos
        margin: '-5px', // Esto anula el margen de los elementos hijos, manteniendo el espacio consistente
      },
      categoryContainer: {
        position: 'relative',
        flex: '1 0 calc(20% - 10px)', // Cambio a 20% porque queremos que cinco elementos llenen el 100%
        height: '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        margin: '5px', // Asegúrate de que este margen se resta del flex-basis en el cálculo anterior
      },
      categoryContainer2: {
        position: 'relative',
        flex: '1 0 calc(20% - 10px)', // Establece la base flexible para llenar el 20% del espacio disponible
        height: '200px', // Altura fija del contenedor
        backgroundPosition: 'center', // Centra la imagen en el contenedor
        overflow: 'hidden', // Oculta cualquier parte de la imagen que exceda del contenedor
        margin: '5px', // Margen alrededor del contenedor
        backgroundSize: '100%'
      },
      categoryContainerRight: {
        position: 'relative',
        width: '45%', // Ancho para los elementos de la derecha
        height: '200px', // Asegúrate de que la altura sea la misma que para los elementos de la izquierda
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        margin: '5px',
        flex: '1 0 45%', // Asume que cada elemento ocupa aproximadamente un 45%

        maxWidth: 'calc(50% - 10px)', // 50% para 2 elementos por fila menos el margen
        order: 3,
      },
    
      categoryContent: {
        position: 'absolute',
        top: '20px', // Se aumenta el espacio desde la parte superior de la imagen
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        width: '100%',
        textDecoration: 'none',
        color: 'inherit',
      },
    
      categoryContentH2: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'blue', // Asegúrate de que el color del texto sea azul.
        display: 'inline-block', // Cambia a 'inline-block' para poder aplicar margen y relleno.
        marginTop: '1rem', // Este es el margen desde el borde superior de la imagen.
        padding: '0.5rem 1rem', // Ajusta el relleno según sea necesario.
        width: 'auto', // O puedes especificar un ancho si es necesario.
        borderRadius: '5px', // Opcional, para bordes redondeados en el fondo del texto.
      },
  };


export default StorePage;
