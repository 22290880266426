import React from 'react';

const LoginPage = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.message}>Please login to your account to make the payment.</h1>
        </div>
    );
};

const styles = {
    container: {
        height: '61vh', // Asegura que el contenedor ocupe toda la altura de la ventana
        display: 'flex', // Utiliza flexbox para los estilos de alineación
        justifyContent: 'center', // Centra el contenido horizontalmente
        alignItems: 'center', // Centra el contenido verticalmente
        backgroundColor: '#222222', // Un color de fondo suave
        padding: '20px' // Un poco de relleno alrededor del contenedor
    },
    message: {
        fontSize: '24px', // Tamaño de fuente grande
        color: '#FFF', // Color de fuente oscuro para contrastar con el fondo claro
        textAlign: 'center' // Asegura que el texto esté centrado
    }
};

export default LoginPage;
