import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// Ejemplo de datos para las FAQ
const faqData = [
  
  {
    question: 'What are you selling?',
    answer: 'We sell: Emulation Firmware for DMA Cards, two type of cheats for CS2/Valorant/Rust; one works on DMA cards and the other one is external machine learn . We sell all this both as a set and separately. You can buy our firmware and cheat, or only cheat.',
  },

  {
    question: 'What is DMA?',
    answer: 'DMA stands for Direct Memory Access. It is a feature of computer systems that allows certain hardware devices, such as network cards, sound cards, or storage devices, to transfer data directly to and from the computer’s memory without involving the central processing unit (CPU). DMA enables more efficient data transfers and frees up the CPU to perform other tasks, improving overall system performance. It is particularly useful for high-speed data transfer operations and real-time applications.',
  },
  {
    question: 'Is firmware requied?',
    answer: 'Yes, the firmware is needed for any device to work, including for a DMA card.',
  },
  {
    question: 'Firmware Warranty',
    answer: 'At the moment we offer lifetime warranty for eac/be, 3 months for Vanguard/ACE and 1 year for Faceit',
  },
  {
    question: 'What is the purchase process?',
    answer: 'You need to join our discord apply server and start with the aplication form.',
  },
  {
    question: 'What payment methods do you accept?',
    answer: 'At the moment, we only accept cryptocurrency (BTC, LTC, ETH, USDT and many others).',
  },
  {
    question: 'Resellers',
    answer: 'We are actively looking for resellers.',
  },

  // Agrega más preguntas y respuestas según sea necesario
];

const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div style={styles.faqItem}>
      <div
        style={styles.question}
        onClick={() => setIsOpen(!isOpen)}
      >
        {faq.question}
      </div>
      {isOpen && <div style={styles.answer}>{faq.answer}</div>}
    </div>
  );
};

const FAQs = () => {
  return (
    <div style={styles.container}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="FAQS, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, Valorant, Rust, DMA, Firmware, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/FAQs" />
      </Helmet>
      <h2>FAQs</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} faq={faq} />
      ))}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#000000',
    color: '#FFF',
  },
  faqItem: {
    marginBottom: '15px',
    color: '#FFF',
  },
  question: {
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#FFF',
  },
  answer: {
    fontSize: '16px',
    color: '#333',
    color: '#FFF',
  },
};

export default FAQs;