import React from 'react';
import Discord from './discord.png';
import { Helmet } from 'react-helmet';

const Contact = () => {
  
  return (
    <div style={styles.leftContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Contact us, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, Valorant, Rust, DMA, Firmware, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/contact" />
      </Helmet>
      <a href="https://discord.com/invite/TDkETDyMB5">
             <img src={Discord} alt="Descripción" style={styles.image} />
             </a>
            <p style={styles.text}>To join us please join our Application Discord</p>
            <p style={styles.text}>The only safe way to apply is via our Application Discord.</p>
            <p style={styles.text}>WE WILL NOT MESSAGE YOU FIRST INSIDE THE DISCORD.</p>
            <p style={styles.text}>Please make sure you message the right person..</p>
            <div style={styles.pad}>

            </div>
      </div>
  );
};
const styles = {
      imagen: {
      width: '500px', // Ajusta el ancho de la imagen según sea necesario
      height: '500px', // La altura se ajustará automáticamente para mantener la proporción
      borderRadius: '5%' , 
    },
    pad: {
      paddingBottom: '4%' ,
    },
    leftContainer: {
      paddingTop: '4%'
    }
};

export default Contact;