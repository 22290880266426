import React from 'react';
import imagenav from './imagenav.png'; // Asegúrate de actualizar esta ruta a la ubicación real de tu imagen.

const Nav = () => {

  return (

            <nav style={styles.nav}>
                    <div style={styles.imagennav}>
                    <img src={imagenav} alt="Descripción de la imagen" style={styles.imagen} />
                    </div>
                    <div>
                        <ul style={styles.navList}>
                        <li><a href="/Home" style={styles.navItem}>HOME&nbsp;&nbsp;</a></li>                       
                        <li><a href="/Status" style={styles.navItem}>STATUS&nbsp;&nbsp;</a></li>
                        <li><a href="/Shop" style={styles.navItem}>SHOP&nbsp;&nbsp;</a></li>
                        <li><a href="/FAQs" style={styles.navItem}>FAQs&nbsp;&nbsp;</a></li>
                        <li><a href="/contact" style={styles.navItem}>CONTACT US</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul style={styles.navList}>
                        <li><a href="/signin" style={styles.navItem}>Sign In</a></li>
                        <li><a href="/register" style={styles.navItem}>Create Account</a></li>
                        </ul>
                    </div>
                    </nav>

  );
};
const styles = {
    mainContainer: {
      color: '#fff',
      backgroundColor: '#181a1b',
      minHeight: '100vh',
      padding: '20px',
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '20px',
      alignItems: 'center',
      paddingRight: '4%',
      backgroundColor: '#181a1b'
    },
    navList: {
      listStyle: 'none',
      padding: 0,
      display: 'flex',
      gap: '20px',
    },
    navItem: {
      color: '#fff',
      textDecoration: 'none',
      
    },
    footer: {
      marginTop: '20px',
      padding: '20px',
      backgroundColor: '#111',
    },
    footerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    footerSection: {
      display: 'flex',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignItems: 'normal',
      alignContent: 'normal' ,
    },
    chatButton: {
      fontSize: '24px',
      padding: '10px',
      backgroundColor: '#007AFF',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight: '200px' ,
      marginTop: '65px' ,
    },
  
    footerChat: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    
    mainTexto: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'space-between' ,
      paddingTop: '3%' ,
      paddingBottom: '3%' ,
    } ,
        imagen: {
        width: '50px', // Ajusta el ancho de la imagen según sea necesario
        height: '50px', // La altura se ajustará automáticamente para mantener la proporción
        borderRadius: '5%' ,
      },
      MainLeft: {
        width: '35%' ,
        marginLeft: '10%' ,
      } ,
      MainRight: {
        width: 'auto' ,
        height: 'auto' ,
        marginRight: '20%' ,
        marginTop: '1.1%' ,
      },
      section1: {
        marginBottom: '20%' ,
      } ,
      section2: {
        marginTop: '20%' ,
      } ,
      imagennav: {
        marginTop: '1%',
        marginLeft: '1%',
      }
  };
  

  export default Nav;


