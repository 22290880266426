import React from 'react';
import { Helmet } from 'react-helmet';

const StatusTableComponent = () => {
  const data = [
    {
      game: 'COUNTER STRIKE 2',
      lastDetection: 'NEVER',
      lastUpdate: '27/06/2024',
      status: 'Undetected',
    },
    {
      game: 'COUNTER STRIKE 2 - DMA',
      lastDetection: 'NEVER',
      lastUpdate: '27/06/2024',
      status: 'Undetected',
    },
    {
      game: 'VALORANT',
      lastDetection: '21/11/2023',
      lastUpdate: '26/06/2024',
      status: 'Updating',
    },
    {
        game: 'VALORANT - DMA',
        lastDetection: 'NEVER',
        lastUpdate: '26/06/2024',
        status: 'Undetected',
      },
      {
        game: 'RUST',
        lastDetection: 'NEVER',
        lastUpdate: '27/06/2024',
        status: 'Undetected',
      },
    // Añade aquí más juegos si los tienes
  ];
  const greenText = { color: '#28a745' };
  const redText = { color: 'red' };

  // Función modificada para decidir el estilo del texto
  const getStyleForText = (text) => {
    if (text === 'NEVER') {
      return greenText;
    } else if (text === '21/11/2023') {
      return redText; // Aquí se aplica el color rojo a la fecha específica
    }
    return null;
  };

  return (
    <table style={styles.table}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Cheat Status SPOOTING's private, undetectable cheating and dma firmware solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="Cheat status, Status, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/Status" />
      </Helmet>
      <thead>
        <tr>
          <th style={styles.th}>Games</th>
          <th style={styles.th}>Last Detection</th>
          <th style={styles.th}>Last update</th>
          <th style={styles.thStatus}>Status</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td style={styles.td}>{item.game}</td>
            <td style={{ ...styles.td, ...getStyleForText(item.lastDetection) }}>{item.lastDetection}</td>
            <td style={{ ...styles.td, ...getStyleForText(item.lastUpdate) }}>{item.lastUpdate}</td>
            <td style={{...styles.tdStatus, ...styles.status[item.status]}}>{item.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const styles = {
    table: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    th: {
      color: 'white',
      padding: '10px',
      textAlign: 'left',
      backgroundColor: '#181a1b'
    },
    td: {
      padding: '10px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    },
    thStatus: {
      backgroundColor: '#181a1b',
      color: 'white',
      padding: '10px',
      textAlign: 'center',
    },
    tdStatus: {
      padding: '10px',
      textAlign: 'center',
      borderBottom: '1px solid #ddd',
      fontWeight: 'bold',
    },
    status: {
      Undetected: {
        backgroundColor: 'green',
      },
      Updating: {
        backgroundColor: '#17a2b8',
      },
      Detected: {
        backgroundColor: 'red',
      },
      Offline: {
        backgroundColor: '#6c757d',
      },
      Risk: {
        backgroundColor: '#ffc107',
      },
      // Añade aquí más estados si los tienes
    },
  };
export default StatusTableComponent;
