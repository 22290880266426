import React from 'react';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div style={styles.mainContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Refund, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, Valorant, Rust, DMA, Firmware, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/refund-policy" />
      </Helmet>
      <div style={styles.mainTexto}>
      <main>
    <h1>Refund Policy</h1>
    <p>We take pride in providing our customers with products of the highest quality. Our stringent quality control measures ensure that each item undergoes thorough inspection before being shipped to our valued customers. We understand that sometimes situations may arise where a return becomes necessary. However, please note that we maintain a very strict return policy to ensure the integrity of our products and the overall shopping experience.</p>
    
    <h2>Disclaimer</h2>
    <p>We make every effort to ensure compatibility of our products with a wide range of hardware. However, due to the vast number of hardware configurations and variations, we cannot guarantee that our products will work flawlessly with every piece of hardware. We are not responsible for any issues of incompatibilities that may arise as a result of using our products with your specific hardware configuration. It is the responsibility of the customer to ensure that their hardware is compatible with our products before making a purchase.</p>
    
    <h2>Eligibility for Returns</h2>
    <p>To be eligible for a return, the product must meet the following criteria:</p>
    
    <p>The product is defective or non-functional upon receipt.</p>
    <p>The product is damaged during transit and the damage is reported within 24 hours of delivery.</p>
    <p>The product does not match the ordered specifications.</p>
   
    <h2>Proof of Hardware Issue</h2>
    <p>For a return to be processed, we require substantial evidence that the hardware is genuinely malfunctioning or defective. You must provide clear documentation, images, or videos demonstrating the specific issue or malfunction. Our customer support team will carefully review the provided evidence to determine eligibility for a return.</p>
    
    <h2>Timeframe for Returns</h2>
    <p>You must notify us of any issues with your product within 3 days of receiving your order. After verifying the hardware issue, we will initiate the return process.</p>
    
    <h2>Non-Eligible Items</h2>
    <p>The following items are not eligible for return under any circumstances:</p>
    <p>Products that have been altered, modified, or damaged due to misuse.</p>
    <p>Items with visible physical damage caused by the customer.</p>
    <p>Products that are no longer in their original condition and packaging.</p>

    <h2>Refund or Replacement</h2>
    <p>Upon receiving and inspecting the returned item, we will determine whether a refund or replacement is appropriate. Refunds will be issued using the original payment method. Replacements will be provided for items that are out of stock or no longer available.</p>
    
    <p>Please note that our return policy is designed to maintain the quality and standard of our products. We appreciate your understanding and cooperation in adhering to this policy. If you have any questions or concerns regarding returns, please contact our customer support team at info@captaindma.com.au or Discord Ticket Support.</p>
    
    <p> is not responsible for any account bans, restrictions, or other consequences that may arise from the use of our products. Users are solely responsible for their actions and or any violations of local or international laws.</p>
	</main>
        </div>
    </div>
  );
};
const styles = {
  mainContainer: {
    color: '#fff',
    backgroundColor: '#000',
    minHeight: '100vh',
    padding: '20px',
  },

  mainTexto: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    alignContent: 'space-between' ,
    paddingTop: '3%' ,
    paddingBottom: '3%' ,
    paddingLeft: '25%' ,
    paddingRight: '25%' ,
  } ,
      imagen: {
      width: '500px', // Ajusta el ancho de la imagen según sea necesario
      height: '500px', // La altura se ajustará automáticamente para mantener la proporción
      borderRadius: '5%' , 
    },
    MainLeft: {
      width: '35%' ,
      marginLeft: '10%' ,
    } ,
    MainRight: {
      width: 'auto' ,
      height: 'auto' ,
      marginRight: '20%' ,
      marginTop: '1.1%' ,
    },
    section1: {
      marginBottom: '20%' ,
    } ,
    section2: {
      marginTop: '20%' ,
    } ,
};

export default Home;