import React from 'react';

const NotFoundPage = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>404</h1>
            <p style={styles.subtitle}>Page Not Found</p>
            <p style={styles.text}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <a href="/" style={styles.homeLink}>Go to Homepage</a>
        </div>
    );
};

// Estilos en JS
const styles = {
    container: {
        color: '#FFFFFF',
        backgroundColor: '#121212',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
        padding: '20px'
    },
    header: {
        fontSize: '72px',
        fontWeight: 'bold',
        margin: '10px 0'
    },
    subtitle: {
        fontSize: '24px',
        margin: '5px 0'
    },
    text: {
        fontSize: '16px',
        margin: '20px 0',
        width: '80%',
        maxWidth: '560px'
    },
    homeLink: {
        color: '#4CAF50',
        fontSize: '18px',
        textDecoration: 'none',
        padding: '10px 20px',
        border: '1px solid #4CAF50',
        borderRadius: '5px',
        marginTop: '20px'
    }
};

export default NotFoundPage;
