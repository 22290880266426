import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(''); // Estado para el email en el modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecovering, setIsRecovering] = useState(false); // Para el indicador de carga
  const [spinnerRotate, setSpinnerRotate] = useState(0); // Estado para la rotación del spinner
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar la presentación del formulario
  const [submittingSpinnerRotate, setSubmittingSpinnerRotate] = useState(0);
  const [loginError, setLoginError] = useState('');
  const [recoveryError, setRecoveryError] = useState('');
  const [submittingMessage, setSubmittingMessage] = useState(''); // Nuevo estado para el mensaje de "Procesando..."
  const [recoveringMessage, setRecoveringMessage] = useState(''); // Nuevo estado para el mensaje de "Procesando..."
      
  useEffect(() => {
    let recoveryInterval;
    if (isRecovering) {
      recoveryInterval = setInterval(() => {
        setSpinnerRotate(prevRotate => (prevRotate + 20) % 360);
      }, 100);
      return () => clearInterval(recoveryInterval);
    }
  }, [isRecovering]);
  
  useEffect(() => {
    let submittingInterval;
    if (isSubmitting) {
      submittingInterval = setInterval(() => {
        setSubmittingSpinnerRotate(prevRotate => (prevRotate + 20) % 360);
      }, 100);
      return () => clearInterval(submittingInterval);
    }
  }, [isSubmitting]);

  const handleLogin = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setLoginError('');
    setSubmittingMessage('SPOOTINGCHEATS.com/db-link/req-67237832'); // Establece el mensaje de "Procesando..." al iniciar el proceso

    setTimeout(() => {
      setIsSubmitting(false);
      setSubmittingMessage(''); // Limpia el mensaje de "Procesando..." al terminar
      setLoginError('Login failed. Incorrect username or password.');
    }, 2000);
  };
  
  const handleRecoverPassword = () => {
    setIsRecovering(true);
    setRecoveryError('');
    setRecoveringMessage('SPOOTINGCHEATS.com/db-link/req-77237832'); // Establece el mensaje de "Procesando..." al iniciar el proceso

    setTimeout(() => {
      setIsRecovering(false);
      setRecoveringMessage(''); // Limpia el mensaje de "Procesando..." al terminar
      setRecoveryError('Incorrect Email');
    }, 2000);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div style={styles.loginContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Sign in SPOOTING's private, undetectable cheating and dma firmware solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="Sing in, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/signin" />
      </Helmet>
      <h2 style={styles.loginTitle}>LOGIN</h2>
      <form onSubmit={handleLogin} style={styles.form}>
        <label htmlFor="username" style={styles.label}>Username or Email Address</label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
        />
        
        <label htmlFor="password" style={styles.label}>Password</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
  
        <div onClick={toggleModal} style={styles.lostPassword}>
          Lost Password
        </div>
  
        <button type="submit" style={styles.submitButton}>Submit</button>
      </form>
  
      {isSubmitting && (
        <div style={styles.spinnerOverlay}>
          <div style={{ ...styles.spinner, transform: `rotate(${submittingSpinnerRotate}deg)` }} />
          {submittingMessage && <div style={styles.processingMessage}>{submittingMessage}</div>}
        </div>
      )}
  
      {!isSubmitting && loginError && <div style={styles.submitErrorMessage}>{loginError}</div>}
  
      {isModalOpen && (
        <div style={styles.modalOverlay} onClick={toggleModal}>
          <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
            <button onClick={toggleModal} style={styles.closeButton}>&times;</button>
            <h2>Forgot your password?</h2>
            <label htmlFor="email" style={styles.label}>Email Address *</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
            />
            {isRecovering ? (
              <div style={styles.spinnerOverlay}>
                <div style={{ ...styles.spinner, transform: `rotate(${spinnerRotate}deg)` }} />
                {recoveringMessage && <div style={styles.processingMessage}>{recoveringMessage}</div>}
              </div>
            ) : (
              <>
                <button onClick={handleRecoverPassword} style={styles.recoverButton}>
                  RECOVER PASSWORD
                </button>
                {recoveryError && <div style={styles.errorMessage}>{recoveryError}</div>}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
const styles = {
  loginContainer: {
    color: 'white',
    backgroundColor: '#181a1b', // Puedes cambiar este color para que coincida con tu diseño
    padding: '40px',
    borderRadius: '5px',
    maxWidth: '300px', // O el ancho que prefieras
    margin: 'auto',
    marginBottom: '5.5%' ,
    marginTop: '8%' ,
  },
  loginTitle: {
    textAlign: 'center',
    marginBottom: '15%'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
  },
  input: {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc', // Personaliza el borde como prefieras
  },
  lostPassword: {
    display: 'block',
    marginBottom: '20px',
    color: 'lightblue', // Personaliza el color como prefieras
    textDecoration: 'none',
    alignSelf: 'center',
    paddingRight: '200px',
    cursor: 'pointer',
    
  },
  submitButton: {
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#3498db', // Personaliza el color del botón como prefieras
    cursor: 'pointer',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000, // Asegura que el modal esté sobre otros elementos
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#181a1b',
    padding: '20px',
    borderRadius: '5px',
    width: '300px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  recoverButton: {
    backgroundColor: '#3498db', // El color que prefieras
    color: 'white',
    padding: '10px',
    margin: '20px 0',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    background: 'none',
    color: 'black',
    cursor: 'pointer',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    position: 'fixed', // Cambiado de 'absolute' a 'fixed' para asegurar visibilidad
    top: '63%', // Ajusta según necesites para que sea visible
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1050,
    backgroundColor: '#040405',
    padding: '10px',
    borderRadius: '5px',
  },
  spinnerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1000,
    paddingBottom: '5%'
  },
  spinner: {
    border: '20px solid #f3f3f3', // Aumentado de 5px a 8px para un aspecto más "gordo"
    borderTop: '20px solid #3498db', // Aumentado de 5px a 8px para mantener la consistencia
    borderRadius: '50%',
    width: '60px', // Aumentado de 50px a 60px para un spinner más grande
    height: '60px', // Aumentado de 50px a 60px para un spinner más grande
  },
  loadingIndicator: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    backgroundColor: 'transparent', // Fondo transparente
    color: '#CCC', // Un color gris claro, similar al de los navegadores
    fontSize: '12px', // Un tamaño de fuente pequeño para simular el indicador del navegador
    padding: '5px 10px', // Un poco de relleno alrededor del texto
    fontFamily: 'sans-serif', // Fuente por defecto del navegador
  },
  spinnerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 2000, // Asegura que el spinner esté por encima de todos los otros elementos
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '24px', // Tamaño grande para que sea claro que es un botón de cierre
    cursor: 'pointer',
  },
  submitErrorMessage: {
    color: 'red', // Ejemplo de color diferente
    textAlign: 'center',
    position: 'fixed',
    top: '20%', // Ajusta según necesites para que sea visible
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 0,
    backgroundColor: '#040405', // Fondo diferente
    padding: '10px',
    top: '68%'
  },
  processingMessage: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    backgroundColor: 'transparent', // Fondo transparente
    color: '#CCC', // Un color gris claro, similar al de los navegadores
    fontSize: '12px', // Un tamaño de fuente pequeño para simular el indicador del navegador
    padding: '5px 10px', // Un poco de relleno alrededor del texto
    fontFamily: 'sans-serif', // Fuente por defecto del navegador
  },
  recoveringMessage: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    backgroundColor: 'transparent', // Fondo transparente
    color: '#CCC', // Un color gris claro, similar al de los navegadores
    fontSize: '12px', // Un tamaño de fuente pequeño para simular el indicador del navegador
    padding: '5px 10px', // Un poco de relleno alrededor del texto
    fontFamily: 'sans-serif', // Fuente por defecto del navegador
  },
};

export default Login;
