import React from 'react';
import { Helmet } from 'react-helmet';
import mes from './1mes.png';
import btc from './btclogo.png';
import ltc from './ltclogo.png';
import eth from './ethlogo.png';
import usdt from './usdtlogo.png';
import { useNavigate } from 'react-router-dom';

const PaymentPage = () => {
  const navigate = useNavigate();

  const handlePayment = (currency) => {
    console.log(`Pago con ${currency}`);
    switch (currency) {
      case 'Bitcoin':
        navigate('/bitcoin');
        break;
      case 'Litecoin':
        navigate('/litecoin');
        break;
      case 'Ethereum':
        navigate('/ethereum');
        break;
      case 'Tether':
        navigate('/tether');
        break;
      case 'Back':
        navigate('/cs2');
        break;
      default:
        console.log('Moneda no soportada');
    }
  };

  return (
    <div style={styles.pageContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="CS2 private, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/buy-cs2-3months" />
      </Helmet>
      <div style={styles.header}>
        <div style={styles.productInfo}>
          <img src={mes} alt="3-month CS2 cheat product" style={styles.productImage} />
          <div>
            <div style={styles.productId}>Product ID: CS2 3</div>
            <div style={styles.productDescription}>CS2 cheat for 3 months</div>
          </div>
        </div>
        <hr style={styles.redDivider} />
      </div>
      <div style={styles.paymentMethod}>
        <div style={styles.paymentMethodTitle}>Payment method</div>
        <div style={styles.total}>Total: $100.00</div>
        <div style={styles.currencyContainer}>
          <button style={styles.currencyButton} onClick={() => handlePayment('Bitcoin')}>
            <img src={btc} alt="Bitcoin payment" style={styles.currencyLogo} /> Bitcoin  BTC
          </button>
          <button style={styles.currencyButton} onClick={() => handlePayment('Litecoin')}>
            <img src={ltc} alt="Litecoin payment" style={styles.currencyLogo} /> Litecoin LTC
          </button>
          <button style={styles.currencyButton} onClick={() => handlePayment('Ethereum')}>
            <img src={eth} alt="Ethereum payment" style={styles.currencyLogo} /> Ethereum ETH
          </button>
          <button style={styles.currencyButton} onClick={() => handlePayment('Tether')}>
            <img src={usdt} alt="Tether payment" style={styles.currencyLogo} /> Tether TRC20 USDT
          </button>
        </div>
        <div style={styles.contenedorback}>
          <button style={styles.backButton} onClick={() => handlePayment('Back')}>Back</button>
        </div>
        <div style={styles.textopie}>
          By making a payment, you agree to the Terms of Use. The information you provide will be processed by the seller in accordance with the Privacy Policy. Your payment will be processed within 60 minutes after network confirmation.
        </div>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: '#333', // A darker background color
    color: 'white', // Text color for better contrast
    padding: '20px', // Some padding around the elements
    fontFamily: 'Arial, sans-serif', // A generic font family
  },
  productContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  productImage: {
    marginRight: '10px',
    width: '100px', // Set your image size
    height: '70px',
  },
  productId: {
    fontWeight: 'bold',
    paddingTop: '20px'
  },
  productDescription: {
    marginBottom: '20px',
  },
  paymentMethod: {
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#222', // A slightly lighter background for the payment method section
    display: 'flex', // Nuevo: Alinea los elementos en filas usando flexbox
    flexDirection: 'column', // Nuevo: Organiza los elementos en una dirección de columna
    alignItems: 'center', // Nuevo: Centra los elementos horizontalmente
    justifyContent: 'center', // Nuevo: Centra los elementos verticalmente (si es necesario)
  },
  divider: {
    margin: '10px 0',
    backgroundColor: '#555', // A color for the divider
  },
  total: {
    fontSize: '1.5em',
    marginBottom: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  currencyContainer: {
    display: 'flex', // Alinea los botones de criptomonedas en una fila
    justifyContent: 'center', // Centra los botones en el contenedor
    marginBottom: '20px', // Espacio debajo del contenedor de botones
    flexWrap: 'wrap', // Permite que los botones se ajusten en la pantalla si hay poco espacio
  },
  currencyButton: {
    marginRight: '10px',
    backgroundColor: '#444', // A neutral color for the buttons
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px'
  },
  backButton: {
    display: 'block',
    backgroundColor: '#666', // A neutral color for the "Back" button
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '10px', // Espacio encima del botón
    borderRadius: '5px'
  },
  currencyLogo: {
    width: '20px', // Ajusta el tamaño según sea necesario
    height: '20px', // Ajusta el tamaño según sea necesario
    marginRight: '10px', // Espacio entre el logo y el texto
    borderRadius: '5px',
    border: 'none'
  },
  header: {
    width: '100%', // Asegúrate de que el encabezado ocupa todo el ancho
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  productInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px', // Espacio entre la imagen y la descripción del producto
  },
  redDivider: {
    height: '2px',
    backgroundColor: 'blue', // Usa el color rojo para la línea
    border: 'none',
    width: '50%', // Ancho de la línea roja
    marginTop: '10px',
    marginBottom: '10px',
  },
  paymentMethodTitle: {
    fontWeight: 'bold',
    fontSize: '24px', // Ajusta esto según tu diseño
    margin: '20px 0',
  },
  textopie:{
    paddingTop:'80px',
    color: 'grey',
    fontSize: '12px',
  },
  contenedorback:{
    paddingTop: '83px',
  },
};

export default PaymentPage;
