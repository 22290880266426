import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
import Home from './Home';
import Status from './Status';
import Shop from './Shop';
import Nav from './Nav';
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import SignIn from './SignIn';
import Create from './Create';
import Footer from './Footer';
import Refund from './Refund';
import Terms from './Terms';
import Hacks from './Hacks';
import Firmware from './Firmware';
import Cs2 from './cs2';
import Cs2Dma from './cs2-dma';
import Valorant from './valorant';
import ValorantDma from './valorant-dma';
import Rust from './rust';
import Buyrust1 from './buyrust1';
import Buyrust2 from './buyrust2';
import Buyrust3 from './buyrust3';
import Buycs1 from './buycs1';
import Buycs2 from './buycs2';
import Buycs3 from './buycs3';
import Buyval1 from './buyval1';
import Buyval2 from './buyval2';
import Buyval3 from './buyval3';
import Buycs2dma1 from './buycs2dma1';
import Buycs2dma2 from './buycs2dma2';
import Buycs2dma3 from './buycs2dma3';
import Buyvaldma1 from './buyvaldma1';
import Buyvaldma2 from './buyvaldma2';
import Buyvaldma3 from './buyvaldma3';
import Buyeacbe from './buyeacbe';
import Buyvang from './buyvanguard';
import Buyfaceit from './buyfaceit';
import Bitcoin from './error004-bitcoin';
import Litecoin from './error004-litecoin';
import Ethereum from './error004-ethereum';
import Tether from './error004-usdt';
import NotFoundPage from './notfoundpage';

function App() {
  return (
    <div className="App">
      <Router>
          <Nav/>
          <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='/Home' element={<Navigate replace to="/" />} />
              <Route path='/Status' element={<Status/>}/>
              <Route path='/Shop' element={<Shop/>}/>
              <Route path='/contact' element={<ContactUs/>}/>
              <Route path='/FAQs' element={<FAQs/>}/>
              <Route path='/SignIn' element={<SignIn/>}/>
              <Route path='/register' element={<Create/>}/>
              <Route path='/refund-policy' element={<Refund/>} />
              <Route path='/terms-of-service' element={<Terms/>} />
              <Route path='/Hacks' element={<Hacks/>} />
              <Route path='/Firmware' element={<Firmware/>} />
              <Route path='/cs2' element={<Cs2/>} />
              <Route path='/cs2-dma' element={<Cs2Dma/>} />
              <Route path='/valorant' element={<Valorant/>} />
              <Route path='/valorant-dma' element={<ValorantDma/>} />
              <Route path='/rust' element={<Rust/>} />
              <Route path='/buy-rust-1month' element={<Buyrust1/>} />
              <Route path='/buy-rust-3months' element={<Buyrust2/>} />
              <Route path='/buy-rust-lifetime' element={<Buyrust3/>} />
              <Route path='/buy-cs2-1month' element={<Buycs1/>} />
              <Route path='/buy-cs2-3months' element={<Buycs2/>} />
              <Route path='/buy-cs2-lifetime' element={<Buycs3/>} />
              <Route path='/buy-valorant-1month' element={<Buyval1/>} />
              <Route path='/buy-valorant-3months' element={<Buyval2/>} />
              <Route path='/buy-valorant-lifetime' element={<Buyval3/>} />
              <Route path='/buy-cs2dma-1month' element={<Buycs2dma1/>} />
              <Route path='/buy-cs2dma-3months' element={<Buycs2dma2/>} />
              <Route path='/buy-cs2dma-lifetime' element={<Buycs2dma3/>} />
              <Route path='/buy-valorantdma-1month' element={<Buyvaldma1/>} />
              <Route path='/buy-valorantdma-3months' element={<Buyvaldma2/>} />
              <Route path='/buy-valorantdma-lifetime' element={<Buyvaldma3/>} />
              <Route path='/buy-eacbe-firmware' element={<Buyeacbe/>} />
              <Route path='/buy-valorant-firmware' element={<Buyvang/>} />
              <Route path='/buy-faceit-firmware' element={<Buyfaceit/>} />
              <Route path='/Bitcoin' element={<Bitcoin/>} />
              <Route path='/Litecoin' element={<Litecoin/>} />
              <Route path='/Ethereum' element={<Ethereum/>} />
              <Route path='/Tether' element={<Tether/>} />
              <Route path='*' element={<NotFoundPage/>} />  // Asegúrate de que esta línea está al final
          </Routes>
          <Footer/>
      </Router>
    </div>
  );
}

export default App;