import React from 'react';
import imagenHack from './hacks.jpg'; // Asegúrate de que la ruta sea correcta.
import imagenFirmware from './firmware.jpg'; // Asegúrate de importar esta imagen.
import { Link } from 'react-router-dom'; // Importa Link
import { Helmet } from 'react-helmet';

const Shop = () => {
  return (
    <div style={styles.mainContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Cheats/Firmware private, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="Firmware, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/Shop" />
      </Helmet>
        <div style={{...styles.columna, position: 'relative'}}> {/* Contenedor de la imagen y texto para Hacks */}
          <a href="/Hacks" style={styles.columnaA}>
            {/* La imagen se ajustará al contenedor padre */}
            <Link to="/Hacks">
            <img src={imagenHack} alt="Hacks" style={styles.columnaImg} />
            </Link>
          </a>
        </div>
        <div style={{...styles.columna, position: 'relative'}}> {/* Contenedor de la imagen y texto para Firmware */}
          <a href="/Firmware" style={styles.columnaA}>
          <Link to="/Firmware">
            <img src={imagenFirmware} alt="Firmware" style={styles.columnaImg} />
          </Link>
          </a>
        </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    color: '#fff',
    backgroundColor: '#000',
    minHeight: '100vh',
    padding: '20px',
    display: 'flex',
  },
  columna: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: '100vh',
    textAlign: 'center', // Asegura que el texto esté centrado.
    marginTop: '5%',
    marginLeft: '2%',
    marginRight: '5%',
    marginBotton: '5%'
  },
  columnaA: {
    textDecoration: 'none',
    color: 'black',
    width: '100%', // Ocupa todo el ancho del contenedor.
    height: '100%', // Ocupa toda la altura del contenedor.
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnaImg: {
    width: '100%',
    height: '80vh',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    borderRadius: '5%' 
  },
};

export default Shop;
