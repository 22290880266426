import React from 'react';
import imagenmain1 from './radarcs2.png'; // Asegúrate de actualizar esta ruta a la ubicación real de tu imagen.
import { Helmet } from 'react-helmet';

const paragraphStyle = {
marginBottom: '50px',
};
const titleStyle = {
  marginTop: '0',
  marginBottom: '50px',
};
const Home2 = () => {
  return (
    <div style={styles.mainContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="CS2 DMA private, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="CS2, DMA, DMA cheats, Counter strike 2, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/cs2-dma" />
      </Helmet>
      <div style={styles.mainTexto}>
      
      <main style={styles.MainRight}>
        {/* Aquí añadimos la imagen */}
        <div>
           <img src={imagenmain1} alt="Logo" style={styles.imagen} />
        </div>
              {/* Más secciones siguiendo el mismo patrón */}
      </main>
      <main style={styles.MainLeft}> 
     
        <section>
        <h2 style={titleStyle}> CS2 DMA RADAR HACK</h2>
        <p style={paragraphStyle}>You can output Radar hack to the screen of the second PC, to your phone, or directly to CS2 on the main PC.</p>
        </section>  

        <section>
        <p style={paragraphStyle}> Now you can share the url with your friends and they can select themselves on the radar.</p>
        </section>

        <section>
        <p style={paragraphStyle}>Unlike other providers SPOOTINGCHEATS cheats never mess with game files, the games memory or anything else which could cause a anti cheat detection. This makes this technology unique and the future of hacking in video games.</p>
        </section>
      </main>
        </div>
    </div>
  );
};
const styles = {
  mainContainer: {
    color: '#fff',
    backgroundColor: '#000',
    padding: '20px',
  },    
  mainTexto: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    alignContent: 'space-between' ,
    paddingTop: '3%' ,
    paddingBottom: '3%' ,
  } ,
      imagen: {
      width: 'auto', // Ajusta el ancho de la imagen según sea necesario
      height: '600px', // La altura se ajustará automáticamente para mantener la proporción
      borderRadius: '5%' , 
      paddingLeft: '15%'
    },
    MainLeft: {
      width: '35%' ,
      marginLeft: '10%',
      marginTop: '5%' ,
    } ,
    MainRight: {
      width: 'auto' ,
      height: 'auto' ,
      marginRight: '20%' ,
      marginTop: '1.1%' ,
    },
    verificationList: {
      display: 'flex',
      justifyContent: 'start', // Alinea los elementos al inicio del contenedor
      alignItems: 'center',
      flexWrap: 'wrap', // Permite que los elementos pasen a la siguiente línea si no hay espacio
      // ... el resto de los estilos que necesites ...
      marginTop: '5%'
  },
  verificationItem: {
      color: '#1AFC03', // Asegúrate de que el color sea visible contra tu fondo
      marginRight: '20px', // Espacio a la derecha de cada ítem
      // ... el resto de los estilos que necesites ...
      marginTop: '4%',
    
  },
};

export default Home2;