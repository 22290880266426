import React from 'react';
import { Helmet } from 'react-helmet';
import imagenmain1 from './ia.jpg';
import Home2 from './home2.js';
import Home3 from './home3.js';

const Home = () => {
  return (
    <div style={styles.mainContainer}>
      <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="Discover SPOOTING's private, undetectable cheating and dma firmware solutions with 24/7 support and no downtime due to game updates." />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/" />
        {/* Agregando Google Tag Manager */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8QMZZ2H3JF"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8QMZZ2H3JF');
          `}
        </script>
      </Helmet>
      <Home2 />
      <Home3 />
      <div style={styles.mainTexto}>
        <main style={styles.MainRight}>
          <img src={imagenmain1} alt="Logo" style={styles.imagen} />
        </main>
        <main style={styles.MainLeft}>
          <h1>Discover the SPOOTING Difference</h1>
          <section>
            <h2>🏆 The Most Secure Cheats Available</h2>
            <p>Due to our self-coded AI technology, we provide cheats far more secure than the mainstream cheating market. Almost all our supported games have been undetected for years since release.</p>
          </section>
          <section>
            <h2>🕙 No Cheat Downtimes Because of Game Updates</h2>
            <p>Since SPOOTING cheats don’t need to read or manipulate the games' memory, all our cheats are unaffected by game updates. Say goodbye to cheat downtimes whenever the game applies an update.</p>
          </section>
          <section>
            <h2>⚙️ 100% Self-Coded by Professionals</h2>
            <p>Unlike most other providers, we develop all cheats ourselves. That way, we can guarantee top quality and first-hand support for all our products.</p>
          </section>
          <section>
            <h2>📞 24/7 Live Chat Support</h2>
            <p>Whenever you have a question or a problem with one of our products, you’ll be able to reach out to our dedicated support team and community. With SPOOTING, you are never being left alone in the dark.</p>
          </section>
        </main>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    padding: '20px',
    '@media (max-width: 768px)': {
      padding: '10px',
      flexDirection: 'column',
    }
  },
  mainTexto: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    alignContent: 'space-between',
    paddingTop: '3%',
    paddingBottom: '3%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    }
  },
  imagen: {
    width: '500px',
    height: '500px',
    borderRadius: '5%',
    '@media (max-width: 768px)': {
      width: '100%',
      height: 'auto',
    }
  },
  MainLeft: {
    width: '35%',
    marginLeft: '10%',
    color: '#FFFFFF',
    '@media (max-width: 768px)': {
      width: '100%',
      marginLeft: '0',
    }
  },
  MainRight: {
    width: 'auto',
    height: 'auto',
    marginRight: '20%',
    marginTop: '1.1%',
    '@media (max-width: 768px)': {
      width: '100%',
      marginRight: '0',
      marginTop: '0',
    }
  },
};

export default Home;
