import React, { useState, useEffect } from 'react';
import imagenmonedas from './monedas.png'; // Asegúrate de actualizar esta ruta a la ubicación real de tu imagen.
import imagendisc from './discord.png';

const Footer = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  useEffect(() => {
    // Carga inicial de países
    fetch('https://restcountries.com/v2/all')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => console.error('Error:', error));

    // Intento de detectar el país del usuario
    fetch('https://ipapi.co/json/') // Asegúrate de que esta API está permitida y es correcta para tu uso
      .then((response) => response.json())
      .then((data) => {
        // Asumiendo que 'data' contiene un campo 'country_code' con el código ISO del país
        setSelectedCountry(data.country_code);
      })
      .catch((error) => console.error('Error al detectar la ubicación:', error));
  }, []);

  return (

        <footer style={styles.footer}>
        <div style={styles.footerContent}>
        <div style={styles.footerSections}>
        <div style={styles.menuizq}>
            <p>Country/region:&nbsp;&nbsp;&nbsp;</p>
            <select style={styles.select} value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
            {countries.map((country) => (
                <option key={country.alpha2Code} value={country.alpha2Code}>
                {country.name}
                </option>
            ))}
            </select>
            </div>
            <div style={styles.menuizq}>
            <p>Language:&nbsp;&nbsp;&nbsp;</p>
            <select style={styles.select} value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
            <option value="en">English</option>
            {/* Más opciones según necesidad */}
            </select>
        </div>
        <div style={styles.menuizq}> 
            <p>All rights reserved &copy; 2018-2024, SPOOTINGCHEATS Services</p>
        </div>
        <div style={styles.menuizq}>
            <a href="/refund-policy">Refund policy</a> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;
            <a href="/terms-of-service">Terms of service</a>
        </div>
        </div>
        <div style={styles.footerSection}>
        {/* Actualiza las rutas a las imágenes según tu proyecto */}
        <div style={styles.section1}>
        </div>
        <div>
        <img src={imagenmonedas} alt="Monedas" style={styles.imagenmonedas} />
        </div>
        </div>
        <div style={styles.footerChat}>
        <a href="https://discord.com/invite/TDkETDyMB5">
          <img src={imagendisc} style={styles.imageStyle} alt="Discord" />
        </a>
        </div>
        </div>
        </footer>
 );
};
const styles = {
    mainContainer: {
      color: '#fff',
      minHeight: '100vh',
      padding: '20px',
    },
    footer: {
      padding: '20px',
      backgroundColor: '#0d0e0e',
    },
    footerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    footerSection: {
      display: 'flex',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignItems: 'normal',
      alignContent: 'normal' ,
    },
    chatButton: {
      fontSize: '50px',
      padding: '10px',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight: '150px' ,
      marginTop: '45px' ,
      color: '#FFF',
    },
  
    footerChat: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFF',
    },
    
    mainTexto: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'space-between' ,
      paddingTop: '3%' ,
      paddingBottom: '3%' ,
    } ,
        imagen: {
        width: '500px', // Ajusta el ancho de la imagen según sea necesario
        height: '500px', // La altura se ajustará automáticamente para mantener la proporción
        borderRadius: '5%' , 
      },
      MainLeft: {
        width: '35%' ,
        marginLeft: '10%' ,
      } ,
      MainRight: {
        width: 'auto' ,
        height: 'auto' ,
        marginRight: '20%' ,
        marginTop: '1.1%' ,
      },
    
      menuizq: {
        display: 'flex' ,
        flexDirection: 'row' ,
        flexWrap: 'nowrap' ,
        justifyContent: 'flex-start' ,
        alignItems: 'center' ,
        alignContent: 'flex-start' ,
        color: '#FFF',
                },
        select: {
            margin: '5px 0',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#181a1b',
            width: '150px', // Ajusta este valor para cambiar el ancho del select
            color: '#FFF',
        },      
        imageStyle: {
          width: '100px', // O el tamaño deseado
          height: 'auto', // Mantiene la proporción de la imagen
          paddingTop: '15%' ,
          marginRight: '40px'
        },
  };
  

  export default Footer;
