import React from 'react';
import aimbotIcon from './aimbot.png';
import wallhackIcon from './wh.png';
import radarHackIcon from './radar.png';
import miscIcon from './misc.png';
import Rustprices from './rustprices.js'
import Rust2 from './rust2.js';
import { Helmet } from 'react-helmet';

const features = [
  {
    title: 'Aimbot',
    details: [
      '● Fov',
      '● Smooth',
      '● Nearest bone',
      '● Multi bone',
      '● RCS',
      '● Humanized',
      '● TriggerBot',
      '● FlickBot',
    ],
    icon: aimbotIcon, // Descomenta y usa tus propias imágenes
  },
  {
    title: 'Wallhack',
    details: [
      '● ESP',
      '● Health bar',
      '● Skeletons',
      '● Distance',
      '● Visibility Check',
    ],
    icon: wallhackIcon, // Descomenta y usa tus propias imágenes
  },
  {
    title: 'Web Radar',
    details: [
      '● Web Radar (on your phone or second PC)',
      '● Shareable Web Radar',
    ],
    icon: radarHackIcon, // Descomenta y usa tus propias imágenes
  },
  {
    title: 'Misc',
    details: [
      '● Instant change of settings',
      '● Cloud CFG',
      '● Weapon detection',
      '● Any weapon skin',
    ],
    icon: miscIcon, // Descomenta y usa tus propias imágenes
  },
];

const Feature = ({ title, details, icon }) => (
  <div style={styles.featureCard}>
    <img src={icon} alt={title} style={styles.icon} />
    <h3 style={styles.featureTitle}>{title}</h3>
    <ul style={styles.featureList}>
      {details.map((detail, index) => (
        <li key={index} style={styles.featureDetail}>{detail}</li>
      ))}
    </ul>
  </div>
);

const FeaturesSection = () => (
  <>
  <Helmet>
        <title>Secure, Private Cheating Solutions | SPOOTING</title>
        <meta name="description" content="RUST private, undetectable cheating solutions with 24/7 support and no downtime due to game updates." />
        <meta name="keywords" content="Rust, cheats, gaming, Bitcoin, Ethereum, Litecoin, Tether, cryptocurrency payment"/>
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://spootingcheats.com/rust" />
      </Helmet>
  <Rustprices/>
  <section style={styles.featuresSection}>
    <h2 style={styles.featuresTitle}>About our AI Cheat for RUST</h2>
    <p style={styles.featuresDescription}>
      The functionality of our cheat is designed with an emphasis on simplicity and security.
      Each function is brought to the ideal and will suit any user, from beginner to professional.
    </p>
    <div style={styles.featuresGrid}>
      {features.map((feature, index) => (
        <Feature key={index} {...feature} />
      ))}
    </div>
    <div style={styles.contenedor2}>
    </div>
    <Rust2/>
  </section>
  </>
);

const styles = {
  featuresSection: {
    background: '#0A0A0A',
    color: 'white',
    padding: '4rem',
    textAlign: 'center',
  },
  featuresTitle: {
    margin: '0 0 2rem',
    fontSize: '2.25rem',
  },
  featuresDescription: {
    margin: '0 0 4rem',
    fontSize: '1rem',
    color: '#DDD',
  },
  featuresGrid: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
  },
  featureCard: {
    background: '#111',
    borderRadius: '10px',
    padding: '2rem',
    maxWidth: '240px',
    textAlign: 'left',
  },
  icon: {
    width: '50px',
    height: '50px',
    marginBottom: '1rem',
  },
  featureTitle: {
    fontSize: '1.25rem',
    marginBottom: '1rem',
  },
  featureList: {
    listStyleType: 'none',
    padding: 0,
    fontSize: '0.9rem',
  },
  featureDetail: {
    marginBottom: '0.5rem',
    marginRight: '100px'
  },
  contenedor2: {
    marginTop: '40px'
  }
};

export default FeaturesSection;
